
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
  font-family: "Montserrat", sans-serif;
}

span.social-icon{
  font-size: large;
  color: #000;
  padding-left: 20px;
  font-size: 18px;
}

span.social-icon:hover{
  color: #000;
}

.me-auto.navbar-nav a {
  color: #fff;
  
}

.me-auto.navbar-nav a:hover {
  color: #f99d00;
  text-decoration: none;
}

.me-auto.navbar-nav a:active {
  color: #f99d00;
  text-decoration: none;
}

body{
  background-color: #ffffff;
  font-family: "Montserrat", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0 0 15px;
    font-weight: 700;
	font-family: 'Montserrat', sans-serif;
}

.swiper-button-next {
  /* background-color: #172138; */
  padding: 23px;
  color: #ffffff !important;
  border-radius: 100%;
  border: 2px solid #ffffff;
  font-size: 12px;
}

.services-images {
  /* margin-top: 210px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next:hover {
  background-color: #FFA100;
  color: #ffffff !important;
  border: 2px solid #FFA100;
}

.swiper-button-prev:hover {
  background-color: #FFA100;
  color: #ffffff !important;
  border: 2px solid #FFA100;
}

.swiper-button-prev {
  /* background-color: #172138; */
  padding: 23px;
  color: #ffffff !important;
  border-radius: 100%;
  border: 2px solid #ffffff;
}

.swiper-button-prev {
  left: 60px !important;
  right: auto;
}

.swiper-button-next {
  right: 60px !important;
  left: auto;
}

a.btn.btn-readmore {
  width: 60%;
  margin: 30px auto;
  color: #fff !important;
    background-color: #337ab7;
    border-color: #2e6da4;
    font-weight: lighter;
}

.count-icon{
  width: 70px;
  height: 70px;
  background-color: #FFA100;
  border-radius: 100%;
  text-align: center;
  font-size: 44px;
  margin: 0 auto;
  justify-content: center;
  color: #fff;
}




select {
  background: #172138;
  border: none;
  color: #fff;
}


/* Navbar.css */

nav.navbar {
  position: sticky;
  top: -3px;
  left: 0;
  z-index: 10;
  box-shadow: 29px 11px 62px 12px rgb(0 0 0 17%);
  
}
.navbar {
    background-color: #172138;
    color: #fff;
    padding: 1rem 0;
    
  }
.heading-text{
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.count{
  width: 100px;
  height: 100px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 100%;
}
  /* .top-btn{
    width: 50px ;
    height: 50px;
    border-radius: 50%;
    background-color: red;
  } */
  
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .brand {
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .menu-btn {
    cursor: pointer;
    display: none;
  }
  
  .menu-btn__burger {
    width: 30px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
  }
  
  .nav-list {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
}
  
  .nav-item {
    margin-right: 1rem;
  }

 
  
  .nav-link {
    color: #fff;
    text-decoration: none;
  }

  .nav-link:hover {
    color: #fdd80c;
    text-decoration: none;
  }

  .dropdown-menu {
    display: block;
    position: absolute;
    background-color: #333;
    padding: 10px;
    list-style-type: none;
    margin-top: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }

  .nav-item {
    margin-right: 10px;
  }
  
  .nav-link {
    color: #fff;
    text-decoration: none;
    padding: 10px;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  .dropdown-item {
    margin: 5px 0;
  }
  
  .dropdown-item a {
    color: #fff;
    text-decoration: none;
  }
  
  .dropdown-item a:hover {
    color: #fea000;
    text-decoration: none;
  }
  /* .dropdown-item a:hover {
    text-decoration: underline;
  } */
  
  @media screen and (max-width: 768px) {
    .menu-btn {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 30px;
      height: 25px;
      background: transparent;
      border: none;
      cursor: pointer;
      z-index: 1;
    }
  
    .menu-btn.open .menu-btn__burger {
      background-color: transparent;
    }
  
    .menu-btn.open .menu-btn__burger::before {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
  
    .menu-btn.open .menu-btn__burger::after {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  
    .nav-list {
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      flex-direction: column;
      align-items: center;
      background-color: #172138;
      padding: 1rem 0;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      pointer-events: none;
    }
  
    .nav-list.open {
      opacity: 1;
      pointer-events: auto;
    }
  
    .nav-item {
      margin: 1rem 0;
    }

    .topnavbar{
      display: none;
    }
  }


  body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
  }
  
  /* a {
    color: #fdd80c ;
    text-decoration: none;
  } */

  .link-text a {
    color: #fdd80c ;
    text-decoration: none;
  }

  .link-text a:hover {
    color: #fdd90c8e ;
    text-decoration: none;
  }

  .footer-text{
    color: #fdd80c ;
  }

  .footer-text:hover{
    color: #fdd90c8e ;
    text-decoration: none !important;
  }
  
  

  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Raleway", sans-serif;
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #fdd80c;
    border-top-color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1s linear infinite;
  }
  
  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #fdd80c;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #f06f54;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 0;
  }
  
  #header.header-scrolled {
    padding: 12px 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #header .logo {
    font-size: 26px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  
  #header .logo a {
    color: #0b2341;
  }
  
  #header .logo img {
    max-height: 40px;
  }
  
  
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
  }

  h4.title a {
    color: #f9f9f9 !important;
    font-weight: bold;
  }

  span.foter-bg a {
    color: #fff;
}

span.social-icon a {
  color: #fff;
}

span.social-icon a:hover {
  color: #FFA100;
}

  h4.title a:hover {
    color: #FFA100;
    text-decoration: none;
  }
  
  .section-bg {
    background-color: #f6f9fd;
  }

  h4.title a {
    color: #3379b6 !important;
}

h4.title a:hover {
  color: #ffa100 !important;
}

a#basic-nav-dropdown:hover {
  color: #ffa100 !important;
}
a#basic-nav-dropdown:active {
  color: #ffa100 !important;
}
  
  .section-title {
    padding-bottom: 40px;
    padding: 40px 0;
    text-align: center;
    width: 20%;
    margin: 0 auto;
    /* text-decoration: underline; */
}
  
  .section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
    font-family: "Poppins", sans-serif;
  }
  
  .section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #fdd80c;
    margin: 4px 10px;
  }
  
  .section-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #0f2f57;
  }
  
  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    padding-left: 28px;
    position: relative;
  }
  
  .about .content ul li+li {
    margin-top: 10px;
  }
  
  .about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #fdd80c;
    line-height: 1;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  a.btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #fdd80c;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #fdd80c;
  }
  
  a.btn-learn-more:hover {
    background: #fdd80c;
    color: #fff;
    text-decoration: none;
  }
  
  /*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
  .counts {
    padding-top: 100px;
  }
  
  .counts .count-box {
    box-shadow: -10px -5px 40px 0 rgba(0, 0, 0, 0.043);
    padding: 30px;
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }

  .counts .count-box:hover {
    background: #172138;
    border-color: #172138;
    transition: all ease-in-out 0.3s;
    color: #fff;
  }
  
  .counts .count-box i {
    display: block;
    font-size: 30px;
    color: #fdd80c;
  }
  
  .counts .count-box span {
    font-size: 42px;
    line-height: 24px;
    display: block;
    font-weight: 700;
    color: #0b2341;
    margin-left: 50px;
  }
  
  .counts .count-box p {
    padding: 0px 0 0 0;
    margin: 0;
    font-family: "Montserrat", sans-serif;
  }
  
  .counts .count-box a {
    font-weight: 600;
    display: block;
    margin-top: 20px;
    color: #164682;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    transition: ease-in-out 0.3s;
  }
  
  .counts .count-box a:hover {
    color: #2169c4;
  }
  
  /*--------------------------------------------------------------
  # Why Us
  --------------------------------------------------------------*/
  .why-us {
    padding: 0;
  }
  
  .why-us .content {
    padding: 60px 100px 0 100px;
  }
  
  .why-us .content h3 {
    font-weight: 400;
    font-size: 34px;
    color: #123a6d;
  }
  
  .why-us .content h4 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
  }
  
  .why-us .content p {
    font-size: 15px;
    color: #848484;
  }
  
  .why-us .video-box {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 400px;
    position: relative;
  }
  
  .why-us .accordion-list {
    padding: 0 100px 60px 100px;
  }
  
  .why-us .accordion-list ul {
    padding: 0;
    list-style: none;
  }
  
  .why-us .accordion-list li+li {
    margin-top: 15px;
  }
  
  .why-us .accordion-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
  }
  
  .why-us .accordion-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 30px;
    outline: none;
    color: #0b2341;
    cursor: pointer;
  }
  
  .why-us .accordion-list span {
    color: #fdd80c;
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
  }
  
  .why-us .accordion-list i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .why-us .accordion-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  
  .why-us .accordion-list .icon-show {
    display: none;
  }
  
  .why-us .accordion-list a.collapsed {
    color: #0b2341;
  }
  
  .why-us .accordion-list a.collapsed:hover {
    color: #fdd80c;
  }
  
  .why-us .accordion-list a.collapsed .icon-show {
    display: inline-block;
  }
  
  .why-us .accordion-list a.collapsed .icon-close {
    display: none;
  }
  
  .why-us .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#fdd80c 50%, rgba(237, 80, 46, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .why-us .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .why-us .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    animation-delay: 0s;
    animation: pulsate-btn 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(237, 80, 46, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .why-us .play-btn:hover::after {
    border-left: 15px solid #fdd80c;
    transform: scale(20);
  }
  
  .why-us .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    animation: none;
    border-radius: 0;
  }
  
  @media (max-width: 1024px) {
  
    .why-us .content,
    .why-us .accordion-list {
      padding-left: 0;
      padding-right: 0;
    }
  }
  
  @media (max-width: 992px) {
    .why-us .content {
      padding-top: 30px;
    }
  
    .why-us .accordion-list {
      padding-bottom: 30px;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
  
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .services .icon-box {
    text-align: center;
    background: #fefefe;
    box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
  }
  
  .services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #fdd80c;
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transform-style: preserve-3d;
    position: relative;
    z-index: 2;
  }
  
  .services .icon-box .icon i {
    color: #fff;
    font-size: 28px;
  }
  
  .services .icon-box .icon::before {
    position: absolute;
    content: "";
    left: -8px;
    top: -8px;
    height: 100%;
    width: 100%;
    background: #fdd80cba;
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    transform: translateZ(-1px);
    z-index: 1;
  }
  
  .services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
  }
  
  .services .icon-box h4 a {
    color: #0b2341;
  }
  
  .services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .services .icon-box:hover {
    background: #fdd80c;
    border-color: #fdd80c;
  }
  
  .services .icon-box:hover .icon {
    background: #172138;
  }
  
  .services .icon-box:hover .icon i {
    color: #fdd80c;
  }
  
  .services .icon-box:hover .icon::before {
    background: #967f02;
  }
  
  .services .icon-box:hover h4 a,
  .services .icon-box:hover p {
    color: #fff;
  }
  
  .heading{
    padding-top: 40px;
  }
  /*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
  .testimonials .testimonial-wrap {
    padding-left: 50px;
  }
  
  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }
  
  .testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px 30px 30px 60px;
    margin: 30px 15px;
    min-height: 200px;
    box-shadow: 0px 0px 20px 0px rgba(11, 35, 65, 0.1);
    position: relative;
    background: #fff;
  }
  
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 10px;
    border: 6px solid #fff;
    position: absolute;
    left: -45px;
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left,
  .testimonials .testimonial-item .quote-icon-right {
    color: #fdedea;
    font-size: 26px;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px auto 15px auto;
  }
  
  .testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #fdd80c;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #fdd80c;
  }
  
  /*--------------------------------------------------------------
  # Cta
  --------------------------------------------------------------*/
  .cta {
    padding: 80px 0;
    background: #0b2341;
  }
  
  .cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta p {
    color: #fff;
  }
  
  .cta .cta-btn {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 35px;
    border-radius: 4px;
    transition: 0.5s;
    margin-top: 10px;
    border: 2px solid #fdd80c;
    color: #fff;
  }
  
  .cta .cta-btn:hover {
    background: #fdd80c;
    border: 2px solid #fdd80c;
  }
  
  /*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
  .portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 25px auto;
    list-style: none;
    text-align: center;
    border-radius: 50px;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #0b2341;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .portfolio #portfolio-flters li:hover,
  .portfolio #portfolio-flters li.filter-active {
    color: #fdd80c;
  }
  
  .portfolio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  
  .portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px;
  }
  
  .portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    color: #0b2341;
  }
  
  .portfolio .portfolio-item .portfolio-info p {
    color: #1a5298;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link,
  .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 40px;
    font-size: 24px;
    top: calc(50% - 18px);
    color: #123a6d;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link:hover,
  .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: #fdd80c;
  }
  
  .portfolio .portfolio-item .portfolio-info .details-link {
    right: 10px;
  }
  
  .portfolio .portfolio-item .portfolio-links {
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 3;
    position: absolute;
    transition: all ease-in-out 0.3s;
  }
  
  .portfolio .portfolio-item .portfolio-links a {
    color: #fff;
    margin: 0 2px;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
  }
  
  .portfolio .portfolio-item .portfolio-links a:hover {
    color: #f59f8c;
  }
  
  .portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
    bottom: 20px;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
  .portfolio-details {
    padding-top: 40px;
  }
  
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #fdd80c;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #fdd80c;
  }
  
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(11, 35, 65, 0.08);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
  }
  
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
  .team .member {
    text-align: center;
    margin-bottom: 20px;
    background: #343a40;
    position: relative;
    overflow: hidden;
  }
  
  .team .member .member-info {
    opacity: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.2s;
  }
  
  .team .member .member-info-content {
    position: absolute;
    left: 50px;
    right: 0;
    bottom: 0;
    transition: bottom 0.4s;
  }
  
  .team .member .member-info-content h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
    color: #fff;
  }
  
  .team .member .member-info-content span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #fff;
  }
  
  .team .member .social {
    position: absolute;
    left: -50px;
    top: 0;
    bottom: 0;
    width: 50px;
    transition: left ease-in-out 0.3s;
    background: rgba(11, 35, 65, 0.5);
    text-align: center;
  }
  
  .team .member .social a {
    transition: color 0.3s;
    display: block;
    color: #fff;
    margin-top: 15px;
  }
  
  .team .member .social a:hover {
    color: #fdd80c;
  }
  
  .team .member .social i {
    font-size: 18px;
    margin: 0 2px;
  }
  
  .team .member:hover .member-info {
    background: linear-gradient(0deg, rgba(11, 35, 65, 0.9) 0%, rgba(11, 35, 65, 0.8) 20%, rgba(0, 212, 255, 0) 100%);
    opacity: 1;
    transition: 0.4s;
  }
  
  .team .member:hover .member-info-content {
    bottom: 30px;
    transition: bottom 0.4s;
  }
  
  .team .member:hover .social {
    left: 0;
    transition: left ease-in-out 0.3s;
  }
  
  /*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
  .pricing .row {
    padding-top: 40px;
  }
  
  .pricing .box {
    padding: 80px 40px;
    margin-bottom: 30px;
    box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
    background: #fff;
    text-align: center;
  }
  
  .pricing h3 {
    font-weight: 300;
    margin-bottom: 15px;
    font-size: 28px;
  }
  
  .pricing h4 {
    font-size: 46px;
    color: #0b2341;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 25px;
  }
  
  .pricing h4 span {
    color: #bababa;
    font-size: 18px;
    display: block;
  }
  
  .pricing ul {
    padding: 0;
    list-style: none;
    color: #999;
    text-align: left;
    line-height: 20px;
  }
  
  .pricing ul li {
    padding-bottom: 12px;
  }
  
  .pricing ul i {
    color: #fdd80c;
    font-size: 18px;
    padding-right: 4px;
  }
  
  .pricing ul .na {
    color: #ccc;
  }
  
  .pricing ul .na i {
    color: #ccc;
  }
  
  .pricing ul .na span {
    text-decoration: line-through;
  }
  
  .pricing .get-started-btn {
    display: inline-block;
    padding: 10px 40px 11px 40px;
    border-radius: 4px;
    color: #0b2341;
    transition: none;
    font-size: 14px;
    font-weight: 600;
    transition: 0.3s;
    border: 2px solid #0b2341;
    background: #fff;
  }
  
  .pricing .get-started-btn:hover {
    background: #0b2341;
    color: #fff;
  }
  
  .pricing .featured {
    z-index: 10;
    padding: 100px 40px;
    border: 4px solid #fdd80c;
  }
  
  .pricing .featured .get-started-btn {
    background: #fdd80c;
    color: #fff;
    border-color: #fdd80c;
  }
  
  .pricing .featured .get-started-btn:hover {
    background: #ab920b;
  }
  
  @media (max-width: 992px) {
    .pricing .box {
      max-width: 60%;
      margin: 0 auto 30px auto;
    }
  }
  
  @media (max-width: 767px) {
    .pricing .box {
      max-width: 80%;
      margin: 0 auto 30px auto;
    }
  }
  
  @media (max-width: 420px) {
    .pricing .box {
      max-width: 100%;
      margin: 0 auto 30px auto;
    }
  }
  
  /*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
  .faq .faq-item {
    margin: 20px 0;
    padding: 20px 0;
    border-bottom: 1px solid #e9f1fb;
  }
  
  .faq .faq-item i {
    color: #669ee5;
    font-size: 20px;
    float: left;
    line-height: 0;
    padding: 13px 0 0 0;
    margin: 0;
  }
  
  .faq .faq-item h4 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    margin: 0 0 10px 28px;
    font-family: "Poppins", sans-serif;
  }
  
  .faq .faq-item p {
    font-size: 15px;
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info-box {
    color: #0b2341;
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 20px 0 30px 0;
    background: #fff;
  }
  
  .contact .info-box i {
    font-size: 32px;
    color: #fdd80c;
    border-radius: 50%;
    padding: 8px;
    /* border: 2px dotted #fbdad2; */
  }
  
  .contact .info-box h3 {
    font-size: 20px;
    color: #777777;
    font-weight: 700;
    margin: 10px 0;
  }
  
  .contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .contact .php-email-form {
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 30px;
    background: #fff;
    width: 100% !important;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .contact .php-email-form input:focus,
  .contact .php-email-form textarea:focus {
    border-color: #fdd80c;
  }
  
  .contact .php-email-form input {
    padding: 10px 15px;
  }
  
  .contact .php-email-form textarea {
    padding: 12px 15px;
  }
  
  .contact .php-email-form button[type=submit] {
    background: #fdd80c;
    border: 0;
    padding: 10px 30px;
    border-radius: 4px;
    color: #fff;
    transition: 0.4s;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: #ab920b;
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background: #f6f9fd;
    min-height: 40px;
    margin-top: 82px;
  }
  
  @media (max-width: 992px) {
    .breadcrumbs {
      margin-top: 74px;
    }
  }
  
  .breadcrumbs h2 {
    font-size: 28px;
    font-weight: 600;
    color: #0b2341;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
  }
  
  .breadcrumbs ol li+li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #123a6d;


  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    background: #071527;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
  }
  
  #footer .footer-top .footer-info {
    margin-bottom: 15px;
    background: #05101e;
    border-top: 4px solid #fdd80c;
    text-align: center;
    padding: 30px 20px;
  }
  
  #footer .footer-top .footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }
  
  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    background: #0a1e38;
  }
  
  #footer .footer-top .social-links a:hover {
    color: #fff;
    background: #fdd80c;
    text-decoration: none;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ab920b;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    color: #ab920b;
  }
  
  #footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
  }
  
  
  
  #footer .copyright {
    border-top: 1px solid #0f2f57;
    text-align: center;
    padding-top: 30px;
    width: 100%;
  }
  
  #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }
  
  @media (max-width: 575px) {
    #footer .footer-top .footer-info {
      margin: -20px 0 30px 0;
    }
  }


.about-box{
  box-shadow: -10px -5px 40px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
}


/* --------------------------------------------------------------
# Features Section
-------------------------------------------------------------- */
.features {
  --background-color: #f4f4f4;
}

.features .content h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding: 10px 20px;
  background: color-mix(in srgb, var(--accent-color), transparent 95%);
  color: var(--accent-color);
  border-radius: 7px;
  display: inline-block;
}

.features .content h2 {
  font-weight: 700;
}

.features .content p:last-child {
  margin-bottom: 0;
}

.features .content .read-more {
  background: var(--accent-color);
  color: var(--contrast-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 12px 24px;
  border-radius: 5px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.features .content .read-more i {
  font-size: 18px;
  margin-left: 5px;
  line-height: 0;
  transition: 0.3s;
}

.features .content .read-more:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
  padding-right: 19px;
}

.features .content .read-more:hover i {
  margin-left: 10px;
}

.features .icon-box {
  background-color: #fff;
  padding: 50px 40px;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 30px;
}

.features .icon-box:hover {
  background-color: #172138;
  transition: all 0.3s ease-out 0s;
  color: #ffffff;
}

.features .icon-box i {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 0;
  transition: all 0.4s ease-out 0s;
  background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
  color: var(--accent-color);
}

.features .icon-box h3 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
}

.features .icon-box p {
  margin-bottom: 0;
}

.features .icon-box:hover i {
  background-color: var(--accent-color);
  color: var(--contrast-color);
}

.features .icon-boxes .col-md-6:nth-child(2) .icon-box,
.features .icon-boxes .col-md-6:nth-child(4) .icon-box {
  margin-top: -40px;
}

@media (max-width: 768px) {

  .features .icon-boxes .col-md-6:nth-child(2) .icon-box,
  .features .icon-boxes .col-md-6:nth-child(4) .icon-box {
    margin-top: 0;
  }
} Section

.features {
  --background-color: #f4f4f4;
}

.features .content h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding: 10px 20px;
  background: color-mix(in srgb, var(--accent-color), transparent 95%);
  color: var(--accent-color);
  border-radius: 7px;
  display: inline-block;
}

.features .content h2 {
  font-weight: 700;
}

.features .content p:last-child {
  margin-bottom: 0;
}

.features .content .read-more {
  background: var(--accent-color);
  color: var(--contrast-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 12px 24px;
  border-radius: 5px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.features .content .read-more i {
  font-size: 18px;
  margin-left: 5px;
  line-height: 0;
  transition: 0.3s;
}

.features .content .read-more:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
  padding-right: 19px;
}

.features .content .read-more:hover i {
  margin-left: 10px;
}



.features .icon-box i {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 0;
  transition: all 0.4s ease-out 0s;
  background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
  color: var(--accent-color);
}

.features .icon-box h3 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
}

.features .icon-box p {
  margin-bottom: 0;
}

.features .icon-box:hover i {
  background-color: var(--accent-color);
  color: var(--contrast-color);
}

.features .icon-boxes .col-md-6:nth-child(2) .icon-box,
.features .icon-boxes .col-md-6:nth-child(4) .icon-box {
  margin-top: -40px;
}

@media (max-width: 768px) {

  .features .icon-boxes .col-md-6:nth-child(2) .icon-box,
  .features .icon-boxes .col-md-6:nth-child(4) .icon-box {
    margin-top: 0;
  }
}


/*--------------------------------------------------------------
# Blog Posts Section
--------------------------------------------------------------*/
.blog-posts {
  padding: 60px 0 20px 0;
}

.blog-posts article {
  box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
  padding: 30px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.blog-posts .post-img {
  max-height: 240px;
  margin: -30px -30px 15px -30px;
  overflow: hidden;
}

.blog-posts .post-category {
  font-size: 16px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin-bottom: 10px;
}

.blog-posts .title {
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog-posts .title a {
  color: var(--heading-color);
  transition: 0.3s;
}

.blog-posts .title a:hover {
  color: var(--accent-color);
}

.blog-posts .post-author-img {
  width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.blog-posts .post-author {
  font-weight: 600;
  margin-bottom: 5px;
}

.blog-posts .post-date {
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Pagination Section
--------------------------------------------------------------*/
.pagination {
  padding-top: 20px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.pagination li {
  margin: 0 5px;
  transition: 0.3s;
}

.pagination li a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination li.active,
.pagination li:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.pagination li.active a,
.pagination li:hover a {
  color: var(--contrast-color);
}

/*--------------------------------------------------------------
# Blog Details Section
--------------------------------------------------------------*/
.blog-details {
  padding-bottom: 30px;
}

.blog-details .article {
  padding: 30px;
  box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-details .post-img {
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog-details .title {
  color: var(--heading-color);
  font-size: 28px;
  font-weight: 700;
  padding: 0;
  margin: 30px 0;
}

.blog-details .content {
  margin-top: 20px;
}

.blog-details .content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog-details .content blockquote {
  overflow: hidden;
  background-color: color-mix(in srgb, var(--default-color), transparent 95%);
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog-details .content blockquote p {
  color: var(--default-color);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog-details .content blockquote:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--accent-color);
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog-details .meta-top {
  margin-top: 20px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog-details .meta-top ul li+li {
  padding-left: 20px;
}

.blog-details .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog-details .meta-bottom {
  padding-top: 10px;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-details .meta-bottom i {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  display: inline;
}

.blog-details .meta-bottom a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  transition: 0.3s;
}

.blog-details .meta-bottom a:hover {
  color: var(--accent-color);
}

.blog-details .meta-bottom .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog-details .meta-bottom .cats li {
  display: inline-block;
}

.blog-details .meta-bottom .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog-details .meta-bottom .tags li {
  display: inline-block;
}

.blog-details .meta-bottom .tags li+li::before {
  padding-right: 6px;
  color: var(--default-color);
  content: ",";
}

.blog-details .meta-bottom .share {
  font-size: 16px;
}

.blog-details .meta-bottom .share i {
  padding-left: 5px;
}

/*--------------------------------------------------------------
# Blog Author Section
--------------------------------------------------------------*/
.blog-author {
  padding: 10px 0 40px 0;
}

.blog-author .author-container {
  padding: 20px;
  box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-author img {
  max-width: 120px;
  margin-right: 20px;
}

.blog-author h4 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
  padding: 0;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog-author .social-links a {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  margin-right: 5px;
}

.blog-author p {
  font-style: italic;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Blog Comments Section
--------------------------------------------------------------*/
.blog-comments {
  padding-top: 10px;
}

.blog-comments .comments-count {
  font-weight: bold;
}

.blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog-comments .comment .comment-img img {
  width: 60px;
}

.blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog-comments .comment h5 a {
  font-weight: bold;
  color: var(--default-color);
  transition: 0.3s;
}

.blog-comments .comment h5 a:hover {
  color: var(--accent-color);
}

.blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin-bottom: 5px;
}

.blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog-comments .reply-form p {
  font-size: 14px;
}

.blog-comments .reply-form input {
  background-color: var(--background-color);
  color: var(--default-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  font-size: 14px;
  border-radius: 4px;
  padding: 10px 10px;
}

.blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: var(--accent-color);
}

.blog-comments .reply-form textarea {
  background-color: var(--background-color);
  color: var(--default-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
  height: 120px;
}

.blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: var(--accent-color);
}

.blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--accent-color);
  color: var(--contrast-color);
}

.blog-comments .reply-form .btn-primary:hover {
  color: var(--contrast-color);
  background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.Outsource a{
  font-size:20px;
  font-weight:600;
  color: #007bff !important;
}

.left-headline{
	margin-bottom: 40px;
}

.choose-wrapper {
    margin: 4rem 0;
}
.left-headline h3 {
    font-size: 26px;
    max-width: 670px;
    line-height: 36px;
    font-weight: 700;
    text-transform: capitalize;
	position: relative;
}
.white-headline{
	position: relative;
}
.white-headline h3{
	color:#fff;
}
.project-headline.white-headline::after{
	position: absolute;
    left: 0;
    top: 5px;
    background: #fff!important;
    content: "";
    width: 5px;
    height: 90px;
    right: 0;
    margin: 0 auto;
}
.color-bg {
	background:#062241 !important;
}
.center-head{
	text-align: center;
}
.center-head{
    padding-bottom: 60px;
}
.center-head h3{
    max-width: 750px;
    font-size: 31px;
    line-height: 42px;
    margin: 0px auto 10px;
}
.center-head::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 40px;
    width: 50px;
    right: 0;
    margin: 0 auto;
    height: 2px;
    background: #062241;
}
.white-headline::after {
    background: #fff;
    position: absolute;
    content: "";
    left: 0;
    bottom: 25px;
    width: 50px;
    right: 0;
    margin: 0 auto;
    height: 2px;
}
.white-headline p {
    color: #fff;
    max-width: 600px;
    letter-spacing: 1px;
    font-size: 16px;
    margin: 10px auto;
}

/* ----------------------------------------  
  6. Services Area
---------------------------------------- */
.all-services{
	margin-top: -30px;
	overflow: hidden;
	display: block;
}


.single-service {
    position: relative;
    border: 1px solid #ddd;
    padding: 20px 20px 5px;
	margin-top: 30px;
	transition: 0.4s;
}
.single-service:hover{
    border: 1px solid #062241;
	background: #062241;
	transition: 0.4s;
}
.left-head{
	position: relative;
}
.left-head h4{
	font-size: 26px;
	line-height: 34px;
	text-transform: capitalize;
}
.service-icon {
    float: left;
    margin-right: 20px;
}
.service-icon i{
	font-size: 34px;
	 color: #FFA100;
}
.service-text h4{
	font-weight: 500;
	font-size: 20px;
}
.single-service h4 {
    font-size: 20px;
    line-height: 28px;
}
.single-service h4 a {
    color: #444;
}
.single-service h4 a:hover{
    color: #FFA100;
}
.left-head p{
	font-size: 16px;
}
.more-btn {
	border: 1px solid #FFA100;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.4s ease 0s;
    background: #FFA100;
    width: 160px;
	border-radius: 30px;
	margin-top: 20px;
}
.more-btn:hover{
    color: #fff;
    background: #062241;
    border: 1px solid #062241;
}
.single-service:hover .service-text h4 a,
.single-service:hover .service-text p{
    color:#fff !important;
}

.icon-style{
  color: #FFA100;
  font-size: 2rem;
}

/* --------------------------------
 15.Home 2  Why choose Area
-------------------------------- */
.choose-area{
  position: relative;
   background: #f9f9f9;
}
.choose-images {
  width: 80px;
  float: left;
  height: 80px;
  font-size: 32px;
  color: #FFA100;
border:1px solid #ccc;
  margin-right: 15px;
  line-height: 75px;
  text-align: center;
  border-radius: 50%;
}
.single-choose {
  display: block;
  margin-top: 30px;
}
.choose-content{
padding-left: 80px;
}
.choose-content h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 10px;
}
.choose-content h4 a {
  color: #444;
}
.choose-content h4 a:hover {
  color: #FFA100;
}
.choose-content p{
  color:#666;
}
.project-area.project-2{
background: #062241;
}
.project-carousel .project-single {
  width: 100%;
  float: none;
}
.project-carousel .add-actions {
  top: 60%;
  padding: 30px 20px;
}
.blog-right-column .blog-image {
  overflow: hidden;
  float: left;
  width: 35%;
}
.blog-right-column .blog-content {
  background: #fff;
  float: left;
  height: 100%;
  width: 65%;
  padding: 0px 20px;
  min-height: 136px;
}
.blog-right-column .single-blog{
overflow: hidden;
}
.footer-2{
background:#062241;
}
.footer-2 .footer-about-top{
background:#0A1828;
}
.footer-2 .footer-area-bottom {
  background: #0A1828;
}

.choose-area {
  position: relative;
  background: #f9f9f9;
}
.choose-image {
  margin: 5rem 0;
}


/*Product Section Start*/
.products{
  background: #fff;
  background-size: cover;
  padding: 80px 0 60px 0;
}
.section-header .section-title {
  font-size: 32px;
  color: #111;

  text-align: center;
  font-weight: 600;
  margin-bottom: 5px;
}
.section-header .section-description {
  text-align: center;
  padding-bottom: 40px;
  color: #999;
}
#products .box {
  padding: 50px 20px;
  margin-bottom: 50px;
  text-align: center;
  border: 1px solid #e6e6e6;
  height: 230px;
  position: relative;
  background: #fafafa;
}
#products .icon {
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
  transition: 0.2s;
  border-radius: 50%;
  border: 6px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 72px;
  height: 72px;
  background: #337ab7;
}
#products .icon i {
  color: #fff;
  font-size: 24px;
}
#products {
  margin-top:3%;
}
.our-products{
margin-bottom:60px;  
}
.our-products h3{
   display: inline-block;
  font-weight: 600;
  position: relative;
}
.our-products h3:after{
  border: 1px solid #333;
  bottom: -20px;
  content: "";
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 40%; 
}
/*Product Section Ends*/


.copyright a {
  color: #FFA100;
}

.copyright > p {
  margin-bottom: 0;
  color: #ddd;
}

.footer-bg{
  background-color: #05101e;
  
}

.topnavbar{
  background-color: #243559;
  padding: 10px 10px;
}



.topnavbarleft{
display: flex;
justify-content: space-around;
color: #ffffff;
align-items: center;
font-size: 14px;
}

.topnavbarright{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #ffffff;
  }

.topbar-left{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topnav-item{
  justify-content: space-between;
}


.footer{
  display: flex;
  padding: 20px;
  color: #ffffff;
}

.footer-icons{
  display: flex;
  align-items: center;
  color: #fff;
  float: right;
}

.foter-bg{
  background-color: #FFA100;
  border-radius: 10%;
  text-align: center;
  display: flex;
  justify-content: center;
  color: #fff;
  margin: 0px 8px;
  padding: 8px ;
  font-size: 14px;
}

.foter-bg:hover{
  background-color: #fac66d;
  cursor: pointer;
}


/* Navbar.css */



.navbar-brand {
  font-size: 1.5rem;
}

.navbar-logo {
  font-weight: bold;
}

.navbar-nav {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-right: 10px;
}

.nav-link {
  color: white;
  text-decoration: none;
  padding: 10px;
  display: block;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #333;
  padding: 10px;
  list-style-type: none;
  margin-top: 5px;
  z-index: 999;
}

.dropdown-menu li {
  margin-bottom: 5px;
}

.dropdown-menu a {
  color: white;
  text-decoration: none;
  display: block;
  padding: 5px 10px;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-item.dropdown {
    position: relative;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
  }

  .nav-item.dropdown:hover .dropdown-menu {
    display: block;
  }
}



.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}
.navBar .logo img {
  float: left;
  max-height: 120px;
}
.is-active {
  color: #ffa100!important;
}

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  display: block;
  color: #ffffff;
  margin: 27px 17px;
  text-decoration: none;
  cursor: pointer;
}
.header__middle__logo{
  /* width: 20%; */
  display: inline-block;
  /* float: left; */
}
.header__middle__menus {
  width: 800px;
  display: inline-block;
  /* float: left; */
}
.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  /* float: right; */
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  display: flex;
  width: 100%;
  /* float: left; */
  /* border-bottom: 1px dashed lavender; */
  position: relative;
  background-color: #172138;
  position: sticky;
  top: -5px;
  left: 0;
  z-index: 10;
  box-shadow: 29px 11px 62px 12px rgb(0 0 0 17%)
}
.header__middle__logo > a > h1 {
  color: #e4d911;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}
/* .active{
  color: #ff1800!important;
} */
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: #162036;
  width: 250px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 25%);
}

@media (max-width:767px) { 
  .header__middle .active {color: #063b8a!important;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus a {
  padding: 0px 0 0px 0;
  margin: 0px 10px;
  border-bottom: 0.5px solid #eeeded1f;
  font-size: 13px;
}
.menu-item:hover > a{
  color: #ffa100 !important;
  text-decoration: none;
}
.menu-item .sub__menus li:hover a{
  color: #ffa100 !important;
  text-decoration: none;
}

.header__middle__logo img{
  max-width: 207px;
  margin: 17px 0 0 0;
  right: -610px;
}

@media (min-width: 780px) {
  .main-nav .menubar__button {
    position: absolute;
  left: 0;
  }
}


@media(max-width:991px){
/* .header__middle__logo {width: 20%;} */
.header__middle__menus {width: 80%;}
.header__middle__logo img {max-width: 197px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: #ffa100;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.main-nav .menubar__button {
  display: block !important;
        float: right;
        background-color: #ffa100;
        color: #fff;
        padding: 6px 7px;
        border-radius: 5px;
        margin: -75px auto 0;
        margin-right: -252px;
        cursor: pointer;
        position: relative;
        z-index: 10037;
}
.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  left: 0;
  z-index: 10007;
  background-color: #172138;
  border-bottom: 3px solid #FFA100;
  right: -120px;
  padding-bottom: 17px;
  /* padding-top: 57px; */
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: #ffffff;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}


li.button-icon {
  /* margin-top: 28px; */
  display: flex;
  justify-content: center;
  align-items: center;
}


.summary h2{
	font-size: 20px;
    line-height: 14px;
	color: #102782;
}	
.summary h3{
	font-size: 20px;
    line-height: 14px;
	color: #102782;
	margin-top: 15px;
}
.safety {
	margin-top:20px;
}
.summary h5{
	font-size:16px;
	line-height:0px;
	margin-top:10px;
}
.description  h4{
	font-size:20px;
}
.circle {
    position: relative;
    margin-top: 1em;
    max-width: 140px;
    max-height: 160px;
    cursor: pointer;
    border-radius: 0.3em;
    padding: 1rem;
    text-align: center;
    transition: transform 0.3s cubic-bezier(0.01, 1.05, 1, 1);
}
.circle .hover-image {
    display: none;
}
.circle .name {
    color: #7A7A7A;
	width: 110%;
  font-size: 14px;
}
.circle:hover {
    background-color: #fff;
    transform: scale(1.1);
    border: 1px solid #00baf2;
    box-shadow: 0 10px 20px 0 rgba(0, 134, 255, 0.2);
}
.circle:hover {
    background-color: #fff;
    transform: scale(1.1);
    border: 1px solid #00baf2;
    box-shadow: 0 10px 20px 0 rgba(0, 134, 255, 0.2);
}

.circle:hover {
  background-color: #fff;
  transform: scale(1.1);
  border: 1px solid #00baf2;
  box-shadow: 0 10px 20px 0 rgba(0, 134, 255, 0.2);
}
.circle:hover .hover-image {
  display: block;
}
.circle:hover .default-image {
  display: none;
}

.footer-icons .fa{
	margin-top: 10px;
}

.SAP-Overview h4, .SAP-Overview h5, .SAP-Overview h6{
  color:#102782;
  font-weight: bold;
  padding-top: 50px;
}

button {
  border: none;
  background-color: #fff;
}

/* .SAP-Overview ul li  {
  color: #0e32bb;
  padding: 0px 10px !important;
} */

/* li {
  padding: 10px 10px;
} */

.headingCont{
  padding: 20px 0px;
}

.passive-safety-text h2 {
  font-weight: bolder;
}

.passive-safety-text h3 {
  font-weight: bolder;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.custom-icon {
  color: #0e32bb;
  font-size: 30px;
  padding-right: 10px;
}

.about-img {
  margin-top: 60px;
}

.left{
  float: left;
}

.right{
  float: right;
}


.navbar-collapse {
  flex-basis: 100%;
  flex-grow:0 !important ; 
  align-items: center;
}

.navbar-light .navbar-brand  {
  color: rgb(255 255 255 90%) !important;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(255 255 255) !important;
}

.dropdown-menu{
  background-color: #172138 !important;
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: #ffa100 !important;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: blue;
}

/* a {
  color: #ffffff !important;
} */

/* a:hover {
  color: #ffa100 !important;
  text-decoration: none !important;
} */

.service-content h4 {
  padding-top: 40px;
  font-weight: bold;
  padding-bottom: 20px;
  font-size: 25px;
    line-height: 24px;
    color: #102782;
}


.caroucaption h3{
  color: #fff;
  font-size: 52px;
  line-height: 60px;
  text-transform: capitalize;
  font-weight: bolder;
}

.caroucaption p{
  color: #fff;
  font-size: 24px;
}

/* .layer-1 h1 {
  color: #fff;
  font-size: 52px;
  line-height: 60px;
  text-transform: capitalize;
  margin: 0px 0px 20px;
  max-width: 710px;
} */

.caroucaption{
  position: absolute;
  top: 15%;
  left: 0%;

}

.ready-btn {
  border: 1px solid #FFA100;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  z-index: 222;
  background: #FFA100;
  width: 160px;
  border-radius: 30px;
}


.summary h2{
  font-size: 20px;
    line-height: 14px;
  color: #102782;
} 
.summary h3{
  font-size: 20px;
    line-height: 14px;
  color: #102782;
  margin-top: 15px;
}
.safety {
  margin-top:20px;
}
.summary h5{
  font-size:16px;
  line-height:0px;
  margin-top:10px;
}
.description  h4{
  font-size:20px;
}
.circle {
    position: relative;
    margin-top: 1em;
    max-width: 140px;
    max-height: 160px;
    cursor: pointer;
    border-radius: 0.3em;
    padding: 1rem;
    text-align: center;
    transition: transform 0.3s cubic-bezier(0.01, 1.05, 1, 1);
}
.circle .hover-image {
    display: none;
}
.circle .name {
    color: #7A7A7A;
  width: 110%;
}
.circle:hover {
    background-color: #fff;
    transform: scale(1.1);
    border: 1px solid #00baf2;
    box-shadow: 0 10px 20px 0 rgba(0, 134, 255, 0.2);
}
.circle:hover {
    background-color: #fff;
    transform: scale(1.1);
    border: 1px solid #00baf2;
    box-shadow: 0 10px 20px 0 rgba(0, 134, 255, 0.2);
}

.circle:hover {
  background-color: #fff;
  transform: scale(1.1);
  border: 1px solid #00baf2;
  box-shadow: 0 10px 20px 0 rgba(0, 134, 255, 0.2);
}
.circle:hover .hover-image {
  display: block;
}
.circle:hover .default-image {
  display: none;
}

.footer-icons .fa{
  margin-top: 10px;
}


.tab-style {
  background-color: white;
  width: 210px;
  margin-top: 30px;
}
.nav-tab{
  color: #337ab7 !important;
}

.nav-tab:hover{
  color: #ffa100 !important;
}

.nav-tab:active{
  color: #ffa100 !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: e7aa11 !important;
  background-color: #ffffff !important;
}

.nav-pills .nav-link.active{
  color: #ffa100 !important;
}

.corporate-heading{
  padding: 20px;
  color: #3c7ab7 !important;
}

.iconstyle{
  text-align: center;
    color: #fb9f01;
    font-size: xx-large;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formstyle{
  margin: 30px;
}

.dropdown-item a:hover {
  color: #fea000 !important;
  text-decoration: none;
  text-decoration: none !important;
}

.single-service:hover .service-text div a, .single-service:hover .service-text p {
  color: white;
}

.single-service:hover {
  border: 1px solid #062241;
  background: #062241;
  transition: 0.4s;
}

.service-content {
  padding: 0px;
}

.service-icon {
  float: left;
  margin-right: 20px;
}

/* ---------------------------------------- 
         6. Services Area
---------------------------------------- */
.all-services{
	margin-top: -30px;
	overflow: hidden;
	display: block;
}
.single-service {
    position: relative;
    border: 1px solid #ddd;
    padding: 20px 20px 5px;
	margin-top: 30px;
	transition: 0.4s;
}
.single-service:hover{
    border: 1px solid #062241;
	background: #062241;
	transition: 0.4s;
}
.left-head{
	position: relative;
}
.left-head h4{
	font-size: 26px;
	line-height: 34px;
	text-transform: capitalize;
}
.service-icon {
    float: left;
    margin-right: 20px;
}
.service-icon i{
	font-size: 34px;
	 color: #FFA100;
}


.service-text h4{
	font-weight: 500;
	font-size: 20px;
}
.single-service h4 {
    font-size: 20px;
    line-height: 28px;
}
.single-service h4 a {
    color: #444;
}
.single-service h4 a:hover{
    color: #FFA100;
}
.left-head p{
	font-size: 16px;
}
.more-btn {
	border: 1px solid #FFA100;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.4s ease 0s;
    background: #FFA100;
    width: 160px;
	border-radius: 30px;
	margin-top: 20px;
}
.more-btn:hover{
    color: #fff;
    background: #062241;
    border: 1px solid #062241;
}
.single-service:hover .service-text h4 a,
.single-service:hover .service-text p{
    color:#fff;
}

.social-icon a {
  color: #fff;
}

.social-icon a:hover {
  color: #FFA100;
  text-decoration: none;
}

.nav-item-style{
  margin-top: 8px;
  margin-right: 10px;
}

.form-error{
  color: red;
}


.swiper-button-prev:after, .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 28px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}

.header-area-2 {
  background: rgba(6, 34, 65, 0.50) none repeat scroll 0 0;
  box-shadow: none;
}

.header-area-2.stick{
background: #062241;
box-shadow: 0px 0px 1px #666, -2px -2px 1px #666;
}

.header-area.stick {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
  box-shadow: 0px 0px 1px #ccc, -2px -2px 1px #ccc;
  background: #fff;
}

